import { Box, Divider, Typography } from '@mui/material'
import React from 'react'

function ProductTitle(props) {
  const {title} = props
  return (
    <>
        <Box sx={{display:'block', flexBox: '1', width:'100%', paddingBottom:'20px'}}>
            <Typography variant='body2' 
            sx={{fontWeight: '700', marginBottom: '10px',}}
            >{title}</Typography>
            <Divider/>
        </Box>
    </>

  )
}

export default ProductTitle