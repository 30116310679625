import Box from '@mui/material/Box'
import CardMedia from '@mui/material/CardMedia'
import React, { useRef, useEffect } from 'react'

const styles = {
    cardimageContainer: {        
        display:{xs: 'none', md:'flex'},
        flexGrow: '1',
        marginTop: '20px',
    },
    imageSettings: {
        display:'flex',
        flexGrow: 1,
        borderRadius: '10px',
        backgroundBlendMode: 'multiply',
        backgroundPosition: '0 50%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        opacity:'0.95',
        transition: 'opacity 5000ms easy-in-out',
        border: '1px solid #E6E9EC'
    },
    logoContainer: {
        position: 'absolute',
        border: '4px solid white',
        height:'60px',
        width:'60px',
        margin: '20px',
    }
}


function ArticleCardImage(props) {
    const {selectedCard, array, index} = props
    const cardRef = useRef()
    const card =selectedCard - (index * 4);
    const article = array[card]
    const articleImage = article.image



    useEffect(()=>{
        cardRef.current.style.backgroundImage='url('+articleImage+')'
    }, [articleImage])

  return (
    <>
        <Box sx={[styles.cardimageContainer]}>
            <Box ref={cardRef} sx={[styles.imageSettings]}>
                <CardMedia component='img' loading='lazy'  image={article.icon} alt={article.name}
                    sx={[styles.logoContainer]}
                />
            </Box>
        </Box>
    </>
  )
}

export default ArticleCardImage