import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import CardHeader from '@mui/material/CardHeader'

import iconCheck from './assets/iconCheck.png'

function ProductAdvantages(props) {
    const {list} = props
    const icon = iconCheck
  return (
    <>
      <Grid container sx={{marginLeft:'-20px', width:'calc(100% + 40px)'}}>
        {list.map((value) => {
              return (
                <Grid item xs={6} md={3} sx={{paddingLeft:'20px', paddingTop:'10px'}}>
                  <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <CardHeader sx={{padding:'0'}}
                      avatar={<Avatar variant='square' src={icon} alt='' sx={{height:'24px', width: '24px'}}/>} />
                    <Typography variant='body2' sx={{fontWeight: '400'}}>{value}</Typography>
                  </Box>
                </Grid>
              ) 
          })}
      </Grid>
    </>

  )
}

export default ProductAdvantages