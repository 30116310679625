import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TypeCard from './components/TypeCard'


function ProductTypes(props) {
  const {list} = props
  return (
    <>
      <Box sx={{paddingTop:{xs:'0', sm:'20px'}}}>
        <Grid container sx={{marginLeft:'-20px', marginTop:'-20px', width:'calc(100% + 20px)'}}>
          {list.map((value) => {
              return (
                <Grid item md={4} sx={{display:'flex', flexGrow:'1', paddingLeft:'20px', paddingTop:'20px'}}>
                  <TypeCard name={value.name} body={value.body}/>
                </Grid>
              )
          })}
        </Grid>
      </Box>
  </>
  )
}

export default ProductTypes