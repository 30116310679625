import { createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

const color1='#03989D'
const color2='#083D83'

export const theme = createTheme({
    palette: {
      primary: {
        main: color1,
        contrastText: '#E6E9EC',
      },
      secondary: {
        main: blue[200],
        contrastText: '#132F4C'
      },
    },

    typography: {
      fontFamily: [
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
  
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
 
      h1: {
        fontSize: '',
        fontWeight: '800',
        lineHeight: '1.114',
        letterSpacing: '0',
        textTransform: 'none',
        color: '#132F4C'
      },
      h2: {
        fontSize: '',
        fontWeight: '800',
        lineHeight: '1.2',
        letterSpacing: '0',
        textTransform: 'none',
        color: '#132F4C'
      },
      h3: {
        fontSize: '',
        fontWeight: '',
        lineHeight: '',
        letterSpacing: '',
        textTransform: 'none',
      },
      h4: {
        fontSize: '',
        fontWeight: '',
        lineHeight: '',
        letterSpacing: '',
        textTransform: 'none',
      },
      h5: {
        fontSize: '',
        fontWeight: '',
        lineHeight: '',
        letterSpacing: '',
        textTransform: 'none',
      },
      h6: {
        fontSize: '',
        fontWeight: '',
        lineHeight: '',
        letterSpacing: '',
        textTransform: 'none',
      },
      subtitle1: {
        fontSize: '',
        fontWeight: '',
        lineHeight: '',
        letterSpacing: '',
        textTransform: 'none',
      },
      subtitle2: {
        fontSize: '',
        fontWeight: '',
        lineHeight: '',
        letterSpacing: '',
        textTransform: 'none',
      },
      body1: {
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5',
        letterSpacing: '0',
        textTransform: 'none',
        color:'#132F4C'
      },
      body2: {
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '1.5',
        letterSpacing: '0',
        textTransform: 'none',
        color: '#132F4C'
      },
      button: {
        fontSize: '1rem',
        fontWeight: '700',
        lineHeight: '1.312',
        letterSpacing: '',
        textTransform: 'none',
        padding: '0.875rem 1rem'
      },
      caption: {
        fontSize: '.6rem'
      }
    },
    
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            border: '1px solid #E6E9EC',
            borderRadius: '10px',
            typography: 'body1',
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
            padding: '0.875rem 1rem',
          },
        },
      },
    },

    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
    },
})

