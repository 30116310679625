import { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme'
import { BrowserRouter } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

import AppRouting from './AppRouting';
import NavBarContainer from './containers/NavBarContainer/NavBarContainer';
import FooterContainer from './containers/FooterContainer/FooterContainer';


const currentTheme = theme

function App() {
  const [path, setPath] = useState('')

  useEffect(()=> {
    setPath('')
  })

  return (
    <>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline/>
        <BrowserRouter>
        <section id='home'></section>
          <NavBarContainer setPath={setPath}/>
            <AppRouting path={path}/>
          <FooterContainer setPath={setPath}/>
        </BrowserRouter>      
      </ThemeProvider>
    </>
  );
}

export default App;

