
import React, { useEffect } from 'react'
import FeaturesContainer from '../containers/FeaturesContainer/FeaturesContainer'
import HeadlineContainer from '../containers/HeadlineContainer/HeadlineContainer'
import DisplaySponsors from '../containers/SponsorsContainer/DisplaySponsors'
import DisplayValues from '../containers/BrandContainer/DisplayValues'
import ContactContainer from '../containers/ContactContainer/ContactContainer'
import HeadlineTest from '../containers/HeadlineTest/HeadlineTest'

function HomePage(props) {

  useEffect(() => {
    if(props.path !== '') {
      document.getElementById(props.path).scrollIntoView({behavior: 'smooth'})
    }
  })

  return (
    <>
      <HeadlineTest/>

      <DisplaySponsors/>

      <section id='productsSection'>
        <FeaturesContainer/>
      </section>

      <DisplayValues/>
      <section id='contactSection'>
      <ContactContainer/>
      </section>
    </>
  )
}

export default HomePage