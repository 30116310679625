import { Box, CardHeader } from '@mui/material'
import React from 'react'

const styles = {
    productContainer: {
        display: 'flex',
        flexGrow:'1',
        aspectRatio: '1 / 1',
        borderRadius: '10px',
        overflow:'hidden'
    },
    imageContainer: {
        display:'flex',
        flexGrow:'1',
        backgroundColor:'#E6E9EC',
        backgroundBlendMode:'multiply',
        backgroundSize:'100%',
        backgroundPosition:'100% 100%',
        backgroundRepeat: 'no-repeat',
        aspectRatio: '1 / 1',
    },
    headerContainer: {
      display: 'flex',
      paddingLeft:{xs:'10px', sm:'20px'},
      alignItems: 'center',
      backgroundColor:'#E6E9ECF5',
      width:'100%',
      height:'max-content',
      marginTop:'auto',
    },
}

function CardProduct(props) {
    const {name, image} = props
  return (
    <>
        <Box sx={[styles.productContainer]}>
            <Box sx={[styles.imageContainer,{backgroundImage: 'url('+image+')'}]}>
                <Box sx={[styles.headerContainer]}>
                    <CardHeader sx={{paddingRight:'0', paddingLeft:'0'}}
                        title={name}
                        titleTypographyProps={{fontWeight: '800'}}
                    />
                </Box>
            </Box>
        </Box>
    </>
  )
}

export default CardProduct