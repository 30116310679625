import React from 'react'
import SectionContainer from '../SectionContainer/SectionContainer'
import ValuesContainer from './container/ValuesContainer'
import { Company } from '../../constants/dataBase'
import Box from '@mui/material/Box'

const styles = {
  displayContainer: {
    backgroundColor: 'white'
  }
}

const values = Company.values



function DisplayValues() {
  
  return (
    <>
      <Box sx={[styles.displayContainer]}>
          <SectionContainer>
                <ValuesContainer arr={values}/>
          </SectionContainer>
      </Box>

    </>
  )
}

export default DisplayValues