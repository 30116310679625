import { Box, TextField, Button } from '@mui/material';
import React, { useRef } from 'react'
import { ContactForms } from '../../constants/dataBase';
import emailjs from '@emailjs/browser'

const styles = {
  formContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '16px',
      marginRight: {xs: '0', sm: '56px'},
  }
}

const fields = ContactForms.fields

function ContactForm() {

  const form = useRef();

  const sendEmail = (e) => {
      e.preventDefault();
      emailjs.init('u8hBE8GaArsQW6SDm')
      emailjs.sendForm('service_m0s9cwi', 'template_1ip1skn', form.current)
          .then(() => {
              console.log('Sent!');
          }, (error) => {
              console.log('Failed!');
          });
  };

return (
  <>
      <form ref={form} onSubmit={sendEmail}>
          <Box sx={[styles.formContainer]}>
              {fields.map(field => {
                  if (field.type === 'TextField')
                      return  <TextField 
                                  id={field.id}
                                  label={field.label}
                                  name={field.name}
                                  defaultValue= ''
                                  multiline={field.multiline}
                                  rows={field.rows}
                                  required={field.required}
                                  sx={{border: '1px solid #E6E9EC'}}
                              />
                  return    <Button 
                              type={field.action}
                              variant='contained'
                              size='large' 
                              sx={{width: {xs: '100%', sm:'150px'}, alignSelf: 'flex-end'}}
                            >
                                {field.label}
                            </Button>

              })}
          </Box>
      </form> 
  </>
)
}

export default ContactForm