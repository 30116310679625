import React, { useState } from 'react'
import Box from '@mui/material/Box'

import SectionContainer from '../../../containers/SectionContainer/SectionContainer'
import SectionContent from '../../../containers/SectionContainer/SectionContent'
import SectionTitle from '../../../containers/SectionContainer/SectionTitle'
import { Products } from '../../../constants/dataBase'
import DisplayProducts from './BraceID/DisplayProducts'
import ProductTitle from './BraceID/ProductTitle'
import ProductTypes from './BraceID/ProductTypes'


function BraceID() {
    const listOfBrands = Products
    const [selectedCard, setSelectedCard] = useState(0)
    const brand = listOfBrands.find(({brand}) => brand === 'BraceID')
    const products = brand.products
    console.log('braceid')
    console.log(brand)
  return (
    <>
        <Box sx={[{flexGrow:'1'}]}>
            <SectionContainer>
                <SectionContent width='12'>
                    <SectionTitle title={brand.brand}/>
                </SectionContent>

                <SectionContent width='12'>
                    <ProductTypes list={products} selectedCard={selectedCard} setSelectedCard={setSelectedCard}/>
                </SectionContent>
            </SectionContainer>

            <Box sx={{marginBottom:'-80px'}}/>

            <SectionContainer>
                <SectionContent width='12'>
                    <ProductTitle title={products[selectedCard].name}/>
                </SectionContent>
                <SectionContent width='12'>
                    <DisplayProducts list={products[selectedCard].products}/>
                </SectionContent>
            </SectionContainer>
        </Box>
    </>
  )
}

export default BraceID