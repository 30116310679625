import { Box, Grid } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import TypeCard from './components/TypeCard'

function ProductTypes(props) {
  const {list, selectedCard, setSelectedCard} = props
  const cardRef = useRef()

  const handleMouseOver = (e) => {
    if(selectedCard !== e){
        document.getElementById(e).style.backgroundColor='#E6E9EC'
        document.getElementById(e).style.cursor='pointer'
        document.getElementById(e).style.filter='grayscale(0.5)'
    }
}

const handleMouseDown = (selected) => {
    if(selected !== selectedCard){
        document.getElementById(selectedCard).style.backgroundColor='transparent'
        document.getElementById(selectedCard).style.border= '1px solid transparent'
        document.getElementById(selectedCard).style.filter= 'grayscale(1)'
        setSelectedCard(selected)
    }
}

const handleMouseLeave = (e) => {
    if(selectedCard !== e){
      document.getElementById(e).style.backgroundColor='transparent'
      document.getElementById(e).style.border= '1px solid transparent'
      document.getElementById(e).style.filter='grayscale(1)'
    }
} 

useEffect(() => {
    document.getElementById(selectedCard).style.filter= 'grayscale(0)'
    document.getElementById(selectedCard).style.border= '1px solid #E6E9EC'
    document.getElementById(selectedCard).style.backgroundColor='white'
    document.getElementById(selectedCard).style.cursor='auto'
}, [selectedCard])

  return (
    <>
      <Box sx={{paddingTop:{xs:'0', sm:'20px'}, flexGrow:'1'}}>
        <Grid container sx={{marginLeft:'-20px', marginTop:'-20px', width:'calc(100% + 20px)'}}>
          {list.map((value, index) => {
              return (
                <Grid item md={4} 
                  sx={{display:'flex', flexGrow:'1', paddingLeft:'20px', paddingTop:'20px'}}>
                  <Box ref={cardRef} id={index} sx={{borderRadius:'10px', display:'flex', flexGrow:'1',
                    filter: 'grayscale(1)', transitionProperty: 'all', transitionDuration: '500ms',}}
                    onMouseDown={()=>handleMouseDown(index)}
                    onMouseEnter={()=>handleMouseOver(index)}
                    onMouseLeave={()=>handleMouseLeave(index)}
                  >
                    <TypeCard name={value.name} body={value.body}/>
                  </Box>
                </Grid>
              )
          })}
        </Grid>
      </Box>
  </>
  )
}

export default ProductTypes