import { Grid } from '@mui/material'
import React from 'react'
import CardProduct from './components/CardProduct'

function DisplayProducts(props) {
    const {list} = props
  return (
    <>
    <Grid container sx={{marginLeft:'-20px', marginTop: '-10px', width:'calc(100% + 20px)'}}>
    {list.map((item) => {
        return (
            <Grid xs={6} sm={4} md={3} sx={{display:'flex', flexGrow:'1', paddingLeft:'20px', paddingTop: '20px'}}>
                <CardProduct name={item.name} image={item.image}/>
            </Grid>
            
        )
    })}
    </Grid>
    </>
  )
}

export default DisplayProducts