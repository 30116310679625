import React from 'react'
import ViewSponsors from './views/ViewSponsors'

function DisplaySponsors() {
  return (
    <>
        <ViewSponsors/>
    </>
  )
}

export default DisplaySponsors