import React from 'react'
import Box from '@mui/material/Box'

import SectionContainer from '../../../containers/SectionContainer/SectionContainer'
import SectionContent from '../../../containers/SectionContainer/SectionContent'
import SectionTitle from '../../../containers/SectionContainer/SectionTitle'
import { Products } from '../../../constants/dataBase'
import ProductAdvantages from './Endurocide/ProductAdvantages'
import ProductColors from './Endurocide/ProductColors'
import ProductTitle from './Endurocide/ProductTitle'
import ProductTypes from './Endurocide/ProductTypes'

function Endurocide() {
   const listOfBrands = Products
   const brand = listOfBrands.find(({brand}) => brand === 'Endurocide')
   const products = brand.products
   const advantages = brand.advantages
   const colors = brand.colors
  return (
    <>
        <Box sx={[{flexGrow:'1'}]}>
            <SectionContainer>
                <SectionContent width='12'>
                    <SectionTitle title={brand.brand}/>
                </SectionContent>

                <SectionContent width='12'>
                    <ProductTypes list={products}/>
                </SectionContent>
            </SectionContainer>

            <Box sx={{marginBottom:'-80px'}}/>
            
            <SectionContainer>
                <SectionContent width='12'>
                    <ProductTitle/>
                </SectionContent>
                <SectionContent width='12'>
                    <ProductAdvantages list={advantages}/>
                </SectionContent>
       
                <SectionContent width='12'>
                    <ProductColors list={colors}/>
                </SectionContent>
            </SectionContainer>

            <Box sx={{marginBottom:'-80px'}}/>
        </Box>
    </>
  )
}

export default Endurocide