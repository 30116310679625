
import { Box } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Products } from '../../constants/dataBase'
import DisplayProducts from './ProductDetails/DisplayProducts'

const products = Products

function ProductsPage() {
    let useParamsId = useParams();
    const brand = products.find(({id}) => id === useParamsId.id)

    return ( 
        <>
            <Box sx={{flexGrow: 1, marginTop: '-40px'}}>
                <DisplayProducts product={brand.brand}/>
            </Box>
        </>
    )
}

export default ProductsPage