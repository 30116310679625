import React from 'react'
import { Routes, Route } from 'react-router-dom'
import HomePage from './pages/HomePage'
import ProductsPage from './pages/ProductsPage/ProductsPage'



export default function AppRouting(props) {
  
  return (
        <Routes>
            <Route exact path='/' element={<HomePage path={props.path} />} />
            <Route path='products' element={<ProductsPage />} />
            <Route path='products/:id' element={<ProductsPage />} />
            <Route path='contact' element={''}  />
        </Routes>
  )
}


/**   <Route path='about' element={<AboutPage />} />
            /> */