import { Box, CardMedia } from '@mui/material'
import React from 'react'

const styles = {
    imageContainer: {
        display: 'flex',
        width: '100%',
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: '',
        margin: '0',
    },
    displayImage: {
        width: '100px',
        aspectRatio: '100 / 52',
        height: '52px',
        objectFit: 'contain',
    },
}


function SponsorCard(props) {
    const logo = props.image
    const alt = props.alt

    return (
        <>
            <Box sx={[styles.imageContainer]}>
                <CardMedia component='img' alt={alt} src={logo} sx={[styles.displayImage]}/>
            </Box>
        </>

    )
}

export default SponsorCard