import { Box, Typography } from '@mui/material'
import React from 'react'

const color1= '#083D83'
const color2= '#0b57ba'

const color3= '#03989D'
const color4= '#016366'


const styles = {
    titleContainer: {
        maxWidth: '500px',
    },

    gradientText: {
        backgroundColor: 'primary',
        backgroundImage:'linear-gradient(166deg, '+color3+' 17%, '+color4+' 68%)',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
}

function SectionTitle({title}) {
   
    if(title === 'Endurocide')
        return (  
            <>
                <Box sx={[styles.titleContainer]}>
                    <Box>
                        <Typography variant='body2' style={styles.gradientText} 
                        sx={{fontWeight: '700', marginBottom: '10px'}}
                        >Endurocide</Typography>
                    </Box>
                    <Box >
                        <Typography variant='h2' 
                        sx={{fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)', color: '#132F4C', margin: '10px 0px'}}
                        >
                            Nem todas as <span style={styles.gradientText}>cortinas</span> são iguais
                        </Typography>
                        <Typography variant='body1' 
                            sx={{marginTop: '10px', maxWidth: '470px'}}>   
                            Cortina semipermanente com eficácia antimicrobiana e esporicida até 2 anos.
                            Reduzem significativamente o risco de infecções hospitalares.
                        </Typography>
                    </Box>
                </Box>
                
                <Box sx={{marginTop: '40px'}}/>
            </>
        )

    if(title === 'BraceID')
        return (  
            <>
                <Box sx={[styles.titleContainer]}>
                    <Box>
                        <Typography variant='body2' style={styles.gradientText} 
                        sx={{fontWeight: '700', marginBottom: '10px'}}
                        >BraceID</Typography>
                    </Box>
                    <Box >
                        <Typography variant='h2' 
                        sx={{fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)', color: '#132F4C', margin: '10px 0px'}}
                        >
                            Para um maior <span style={styles.gradientText}>comforto</span> e uma melhor recuperação
                        </Typography>
                        <Typography variant='body1' 
                            sx={{marginTop: '10px', maxWidth: '470px'}}>   
                            Os produtos da BraceId utilizam tecidos e materiais confortáveis, designs anatómicos corretos e bem adaptados, para uma maior eficácia na recuperação
                        </Typography>
                    </Box>
                </Box>
                
                <Box sx={{marginTop: '40px'}}/>
            </>
        )

        if(title === 'LusaMed')
        return (  
            <>
                <Box sx={[styles.titleContainer]}>
                    <Box>
                        <Typography variant='body2' style={styles.gradientText} 
                        sx={{fontSize: '0.875', fontWeight: '700', lineHeight: '1.5', letterSpacing: '0', marginBottom: '10px'}}
                        >Suplementos Alimentares</Typography>
                    </Box>
                    <Box >
                        <Typography variant='h2' 
                        sx={{fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)', fontWeight: '800', lineHeight: '1.2', color: '#132F4C', margin: '10px 0px'}}
                        >
                            Para <span style={styles.gradientText}>complementar</span> sempre <br/> que precisar
                        </Typography>
                        <Typography variant='body1' 
                        sx={{marginTop: '10px', maxWidth: '470px'}}>
                            Os nossos suplementos constituem fontes concentradas de nutrientes ou outros ingredientes com efeito nutricional e fisiológico.
                        </Typography>
                    </Box>
                </Box>
                
                <Box sx={{marginTop: '40px'}}/>
            </>
        )

        if(title === 'Values')
        return (  
            <>
                <Box sx={[styles.titleContainer]}>
                    <Box>
                        <Typography variant='body2' style={styles.gradientText} 
                        sx={{fontWeight: '700', marginBottom: '10px'}}
                        >
                            Porquê trabalhar connosco?
                        </Typography>
                    </Box>
                    <Box >
                        <Typography variant='h2' 
                        sx={{fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)', fontWeight: '800', lineHeight: '1.2', color: '#132F4C', margin: '10px 0px'}}
                        >
                            A colaboração, a <span style={styles.gradientText}>qualidade</span> <br/>e o compromisso
                        </Typography>
                        <Typography variant='body1' 
                        sx={{marginTop: '10px', maxWidth: '470px'}}>   
                            A missão de disponibilizar os melhores produtos no mercado. 
                            A visão de que todos merecem o melhor.
                        </Typography>
                    </Box>
                </Box>
                
                <Box sx={{marginTop: '40px'}}/>
            </>
        )

        if(title === 'lusamed')
        return (  
            <>
                <Box sx={[styles.titleContainer]}>
                    <Box>
                        <Typography variant='body2' style={styles.gradientText} 
                        sx={{fontSize: '0.875', fontWeight: '700', lineHeight: '1.5', letterSpacing: '0', marginBottom: '10px'}}
                        >Os nossos produtos</Typography>
                    </Box>
                    <Box >
                        <Typography variant='h2' 
                        sx={{fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)', fontWeight: '800', lineHeight: '1.2', color: '#132F4C', margin: '10px 0px'}}
                        >
                            Descubra a gama de <span style={styles.gradientText}>produtos</span> que temos à sua disposição
                        </Typography>
                        <Typography variant='body1' 
                        sx={{marginTop: '10px', maxWidth: '470px'}}>   
                            Produtos certificados e de alta qualidade. 
                            Desenvolvidos por especialistas e testados segundo as normas Europeias.
                        </Typography>
                    </Box>
                </Box>
                
                <Box sx={{marginTop: '40px'}}/>
            </>
        )

        if(title === 'Contacto')
        return (
            <>
            <Box sx={[styles.titleContainer]}>
                <Box>
                <Typography variant='body2' style={styles.gradientText} 
                        sx={{fontSize: '0.875', fontWeight: '700', lineHeight: '1.5', letterSpacing: '0', marginBottom: '10px'}}
                >
                        Podemos ajudar?
                </Typography>
                </Box>
                <Box>
                    <Typography variant='h2' 
                        sx={{fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)', fontWeight: '800', lineHeight: '1.2', color: '#132F4C', margin: '10px 0px'}}
                    >
                        Para mais <span style={styles.gradientText}>informações</span> sobre os nossos produtos
                    </Typography>
                    <Typography variant='body1' 
                        sx={{ marginTop: '10px', maxWidth: '470px'}}>      
                        Utilize o nosso formulário de contacto e coloque as suas dúvidas. Estamos ao dispor para o que for necessário.
                    </Typography>
                </Box>
            </Box>
            <Box sx={{marginTop: '40px'}}/>
            </>
        )
}

export default SectionTitle