import { Box, Container, Grid } from '@mui/material'
import React from 'react'

const styles = {
    gridContainer: {
        marginTop: '-20px',
        marginLeft: '-20px',
        width: 'calc(100% + 20px)',
    },
    sectionContainer: {
        paddingTop: '80px',
        paddingBottom: '80px',
    },
}



function SectionContainer(props) {
    const {children} = props

    return (
        <>
            <Box sx={styles.sectionContainer}>
                <Container maxWidth='lg'>
                    <Grid container sx={[styles.gridContainer]}>
                        {children}
                    </Grid>
                </Container>
            </Box>
        </>
 
  )
}

export default SectionContainer