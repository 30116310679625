import React, { useContext, useState, useId} from 'react'
import Box from '@mui/material/Box'

import SectionContent from '../../containers/SectionContainer/SectionContent'
import SectionTitle from '../../containers/SectionContainer/SectionTitle'
import { ProductsContext } from '../../constants/contexts'
import ArticleCard from './components/ArticleCard'
import ArticleCardImage from './components/ArticleCardContent/ArticleCardImage'

function ArticlesContainer({title, index}) {
    const ID = useId()
    let x = index * 4
    const [selectedCard, setSelectedCard] = useState(x)
    const article = useContext(ProductsContext)

    return (
      <>
        <SectionContent width={6}>
          <SectionTitle key={ID + title} title={title}/>
          <Box sx={{gap:'10px', display:'flex', flexDirection: 'column'}}>
          {article.map((_, index) => {
            if(selectedCard>7) {
            return (
              index = index + 8,
              <ArticleCard key={ID + _.id}
                title={_.type} subtitle={_.name} body={_.brand} icon={_.icon} index={index}
                selectedCard={selectedCard} setSelectedCard={setSelectedCard}
              />
            )} if(selectedCard > 3 && selectedCard <8) {
              return(
                index = index + 4,
                <ArticleCard key={ID + _.id}
                title={_.type} subtitle={_.name} body={_.brand} icon={_.icon} index={index}
                selectedCard={selectedCard} setSelectedCard={setSelectedCard}
              />
              )
            }
            return (
              <ArticleCard key={_.id} 
                title={_.type} subtitle={_.body} body={_.brand} icon={_.icon} index={index} ID={_.id}
                selectedCard={selectedCard} setSelectedCard={setSelectedCard}
              />
            )
          })}
          </Box>
        </SectionContent>
        <SectionContent width={6}>
            <ArticleCardImage selectedCard={selectedCard} array={article} index={index}/>
        </SectionContent>
      </>

    )
}

export default ArticlesContainer


/**
 * 
 *             <ArticleCardContent  selectedCard={selectedCard} array={article} index={index}/>

 */

