import { Box, CardHeader, Typography } from '@mui/material'
import React from 'react'

const styles = {
    productContainer: {
        display: 'flex',
        flexGrow:'1',
        aspectRatio: '1 / 1',
        borderRadius: '10px',
        overflow:'hidden'
    },
    imageContainer: {
        display:'flex',
        flexGrow:'1',
        backgroundColor:'#E6E9EC',
        backgroundBlendMode:'multiply',
        backgroundSize:'100%',
        backgroundPosition:'100% 100%',
        backgroundRepeat: 'no-repeat',
        aspectRatio: '1 / 1',
    },
    headerContainer: {
      display: 'flex',
      paddingLeft:{xs:'10px', sm:'20px'},
      backgroundColor:'#E6E9ECF5',
      width:'100%',
      height:'max-content',
      marginTop:'auto',
    },
    priceContainer: {
        display:'flex',
        flexGrow:'1',
        justifyContent: 'flex-end',
        paddingRight: {xs:'10px', sm:'20px'},
        paddingBottom: '16px'
    },
}

function CardProduct(props) {
    const {name, image, body, pvr } = props

    return (
        <>
            <Box sx={[styles.productContainer]}>
                <Box sx={[styles.imageContainer,{backgroundImage: 'url('+image+')'}]}>
                    <Box sx={[styles.headerContainer]}>
                        <CardHeader sx={{paddingRight:'0', paddingLeft:'0'}} 
                            title={name} subheader={body}
                            titleTypographyProps={{fontWeight:'800'}}
                        />
                        <Box sx={styles.priceContainer}>
                            <Typography variant='body2' sx={{fontWeight: '500', alignSelf:'flex-end'}}>pvr: <span style={{fontWeight:'800', fontSize:'16px'}}>{pvr} €</span></Typography>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default CardProduct