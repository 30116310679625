import React, { useRef, useEffect } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import { useParams } from 'react-router-dom'

const styles = {
    cardContainer: {
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: '10px',
        flexGrow: '1',
        justifyContent: 'flex-start',
        textAlign: 'left',
        border: '1px solid transparent',
        transitionProperty: 'all',
        transitionDuration: '500ms',
        color: '#66B2FF',
        padding: '20px',
        maxWidth: '500px',
        filter: 'grayscale(1)'
    },
    clearMargins: {
        margin: '0',
        padding: '0',
    },
}

function ArticleCard(props) {
    const {title, name, subtitle, body, ID, index, selectedCard, setSelectedCard} = props
    const cardRef = useRef()
    let {Id} = useParams()
    console.log('useparams')
    console.log(Id)

    const handleMouseOver = (e) => {
        if(selectedCard !== e){
            cardRef.current.style.backgroundColor='#E6E9EC'
            cardRef.current.style.cursor='pointer'
            cardRef.current.style.filter='grayscale(0.5)'
        }
    }
    
    const handleMouseDown = (selected) => {
        if(selected !== selectedCard){
            document.getElementById(selectedCard).style.backgroundColor='transparent'
            document.getElementById(selectedCard).style.border= '1px solid transparent'
            document.getElementById(selectedCard).style.filter= 'grayscale(1)'
            setSelectedCard(selected)
        }
    }

    const handleMouseLeave = (e) => {
        if(selectedCard !== e){
            cardRef.current.style.backgroundColor='transparent'
            cardRef.current.style.border= '1px solid transparent'
            cardRef.current.style.filter='grayscale(1)'
        }
    } 

    useEffect(() => {
        document.getElementById(selectedCard).style.filter= 'grayscale(0)'
        document.getElementById(selectedCard).style.border= '1px solid #E6E9EC'
        document.getElementById(selectedCard).style.backgroundColor='white'
        cardRef.current.style.cursor='auto'
    }, [selectedCard])

    return (
        <>
            <Button type='button' sx={[styles.cardContainer]}
                id={index} ref={cardRef}
                onMouseDown={()=>handleMouseDown(index)} 
                onMouseOver={()=>handleMouseOver(index)} 
                onMouseLeave={()=>handleMouseLeave(index)}
            >   
                <CardHeader sx={[styles.clearMargins]} avatar={<Avatar variant='circle'  alt={name} sx={[styles.avatar, {marginRight: '4px', backgroundColor:'#03989D', color:'#03989D', height:'36px', width: '36px'}]}/>}/>
                <CardContent sx={[styles.clearMargins]}>
                    <Typography variant='body1'
                        sx={{fontWeight:'800', color:'#1A2027'}}
                    >
                        {title}
                    </Typography>
                    <Typography variant='body2'
                        sx={{color: '#3E5060', marginTop: '5px', marginBottom: '5px'}}
                    >
                        {subtitle}
                    </Typography>
                    <Typography variant='body2'
                        sx={{color: '#3E5060', marginTop: '5px', marginBottom: '5px'}}
                    >
                        {body}
                    </Typography>
                    <Button href={'products/'+ID}
                        sx={{display: 'block', padding: '0', all:'unset', '&:hover':{backgroundColor: 'transparent'}, cursor: 'pointer'}}>
                        <Typography variant='body1' fontWeight={700} color='primary'>
                            Ver mais &gt;
                        </Typography>
                        
                    </Button>
                </CardContent>
            </Button>
        </>
    )
}

export default ArticleCard