import Endurocide from '../assets/endurocide.jpeg'
import BraceID from '../assets/braceid.jpg'
import LusaMed from '../assets/lusamed.png'
import IconAtom from '../assets/iconatom.png'
import EndurocideLogo from '../assets/endurocidelogo.png'
import BraceIDLogo from '../assets/braceidlogo.png'
import LusaMedLogo from '../assets/lusamedlogo.png'
import LusaMedIcon from '../assets/lusamedicon.png'
import AvatarEnd from '../assets/avatarendurocide.png'
import AvatarBid from '../assets/avatarbraceid.png'
import AvatarLmed from '../assets/avatarlusamed.png'
import EndSponsor from '../assets/endsponsor.png'
import BioSponsor from '../assets/biosponsor.png'
import BraSponsor from '../assets/brasponsor.png'
import OrtSponsor from '../assets/ortsponsor.png'
import OtlSponsor from '../assets/otlsponsor.png'

import color1 from '../pages/ProductsPage/ProductDetails/Endurocide/assets/color1.png'
import color2 from '../pages/ProductsPage/ProductDetails/Endurocide/assets/color2.png'
import color3 from '../pages/ProductsPage/ProductDetails/Endurocide/assets/color3.png'
import color4 from '../pages/ProductsPage/ProductDetails/Endurocide/assets/color4.png'
import color5 from '../pages/ProductsPage/ProductDetails/Endurocide/assets/color5.png'
import color6 from '../pages/ProductsPage/ProductDetails/Endurocide/assets/color6.png'
import color7 from '../pages/ProductsPage/ProductDetails/Endurocide/assets/color7.png'
import color8 from '../pages/ProductsPage/ProductDetails/Endurocide/assets/color8.png'
import color9 from '../pages/ProductsPage/ProductDetails/Endurocide/assets/color9.png'
import color10 from '../pages/ProductsPage/ProductDetails/Endurocide/assets/color10.png'
import color11 from '../pages/ProductsPage/ProductDetails/Endurocide/assets/color11.png'
import color12 from '../pages/ProductsPage/ProductDetails/Endurocide/assets/color12.png'

import altaair from '../pages/ProductsPage/ProductDetails/BraceID/assets/altaair.png'
import crianca from '../pages/ProductsPage/ProductDetails/BraceID/assets/crianca.png'
import estabilizadortt from '../pages/ProductsPage/ProductDetails/BraceID/assets/estabilizadortt.png'
import estabilizadortt3 from '../pages/ProductsPage/ProductDetails/BraceID/assets/estabilizadortt3.png'
import imobilizadorjoelho from '../pages/ProductsPage/ProductDetails/BraceID/assets/imobilizadorjoelho.png'
import joalheiraart from '../pages/ProductsPage/ProductDetails/BraceID/assets/joalheiraart.png'
import joalheiraesta from '../pages/ProductsPage/ProductDetails/BraceID/assets/joalheiraesta.png'
import peelastico8 from '../pages/ProductsPage/ProductDetails/BraceID/assets/peelastico8.png'
import protetortrauma from '../pages/ProductsPage/ProductDetails/BraceID/assets/protetortrauma.png'
import quatropontos from '../pages/ProductsPage/ProductDetails/BraceID/assets/quatropontos.png'
import romjoelho from '../pages/ProductsPage/ProductDetails/BraceID/assets/romjoelho.png'
import romjoelhocool from '../pages/ProductsPage/ProductDetails/BraceID/assets/romjoelhocool.png'
import romwalker from '../pages/ProductsPage/ProductDetails/BraceID/assets/romwalker.png'
import shieldalta from '../pages/ProductsPage/ProductDetails/BraceID/assets/shieldalta.png'
import walkeralta from '../pages/ProductsPage/ProductDetails/BraceID/assets/walkeralta.png'

import spineboard from '../pages/ProductsPage/ProductDetails/BraceID/assets/spineboard.png'
import suportelombar from '../pages/ProductsPage/ProductDetails/BraceID/assets/suportelombar.png'
import cintaabdominal from '../pages/ProductsPage/ProductDetails/BraceID/assets/cintaabdominal.png'
import colarcervicalrig from '../pages/ProductsPage/ProductDetails/BraceID/assets/colarcervicalrig.png'
import colarespuma from '../pages/ProductsPage/ProductDetails/BraceID/assets/colarespuma.png'

import imobilizador from '../pages/ProductsPage/ProductDetails/BraceID/assets/imobilizador.png'
import dedo from '../pages/ProductsPage/ProductDetails/BraceID/assets/dedo.png'
import bracadeira from '../pages/ProductsPage/ProductDetails/BraceID/assets/bracadeira.png'
import almofadaabducao from '../pages/ProductsPage/ProductDetails/BraceID/assets/almofadaabducao.png'
import ortosling from '../pages/ProductsPage/ProductDetails/BraceID/assets/ortosling.png'
import polegar from '../pages/ProductsPage/ProductDetails/BraceID/assets/polegar.png'
import polegarpunho from '../pages/ProductsPage/ProductDetails/BraceID/assets/polegarpunho.png'
import punholonga from '../pages/ProductsPage/ProductDetails/BraceID/assets/punholonga.png'
import rom from '../pages/ProductsPage/ProductDetails/BraceID/assets/rom.png'
import slinguniversal from'../pages/ProductsPage/ProductDetails/BraceID/assets/slinguniversal.png'

import gutduomax from '../pages/ProductsPage/ProductDetails/LusaMed/assets/lusamedgutduo.png'
import gutflora from '../pages/ProductsPage/ProductDetails/LusaMed/assets/lusamedgutflora.png'

export const navLinks = [
    {
        id: 'nav_1',
        name: 'Home',
        path: 'home',
        menu: 0,
        display: 1,
    },
    {
        id: 'nav_2',
        name: 'Produtos',
        path: 'productsSection',
        menu: 1,
        display: 1,
    },
    {
        id: 'nav_3',
        name: 'Sobre Nós',
        path: '/about',
        menu: 0,
        display: 0,
    },
    {
        id: 'nav_4',
        name: 'Contacto',
        path: 'contactSection',
        menu: 0,
        display: 1,
    },
];

export const Company = {
        id: 'lusamed',
        name: 'LusaMed',
        logo: LusaMedLogo,
        address: '',
        email: '',
        contact: '',
        values: [
            {
                id: 'value1',
                name: 'Inovação',
                icon: IconAtom,
                title: 'A busca pela inovação, pelo futuro',
                body: 'Procuramos por novas ideias, novas tecnologias, novos serviços, para novas realidades.',
                display: 1,
            },
            {
                id: 'value2',
                name: 'Sustentabilidade',
                icon: IconAtom,
                title: 'Sustentabilidade',
                body: 'Trabalhamos com equipas conscientes que colaboram para um mundo melhor. Produtos que ajudam as gerações futuras.',
                display: 1,
            },
            {
                id: 'value3',
                name: 'Colaboração',
                icon: IconAtom,
                title: 'Colaboração',
                body: 'Acreditamos que agir com um objetivo comum e em sinergia é possivel atingir melhores resultados.',
                display: 1,
            },
            {
                id: 'value4',
                name: 'Excelência',
                icon: IconAtom,
                title: 'Excelência',
                body: 'Trabalhamos para a melhoria continua dos resultados e da satisfação das necessidades dos nossos clientes.',
                display: 1,
            },
        ],
};

export const Sponsors = [
    {
        id: 'sponsor_1',
        name: 'BraceID',
        image: BraSponsor,
        url: 'http://www.braceid.com'
    },
    {
        id: 'sponsor_2',
        name: 'Bio Technics',
        image: BioSponsor,
        url: 'http://www.biotechnics.co.uk'
    },
    {
        id: 'sponsor_3',
        name: 'Endurocide',
        image: EndSponsor,
        url: 'http://www.endurocide.com'
    },
    {
        id: 'sponsor_4',
        name: 'The OTL Group',
        image: OtlSponsor,
        url: 'http://www.the-otlgroup.com/'
    },
    {
        id: 'sponsor_5',
        name: 'Orthobroker',
        image: OrtSponsor,
        url: 'http://www.orthobroker.com'
    },
]

export const Brands = [
    {
        id: 'brand_1',
        companyId: Company.id,
        name: 'Endurocide',
        type: 'Curtinas Hospitalares',
        body: 'Alternativa rentável às cortinas hospitalares tradicionais.',
        image: Endurocide,
        icon: EndurocideLogo,
        avatar: AvatarEnd,
    },
    {
        id: 'brand_2',
        companyId: Company.id,
        name: 'BraceID',
        type: 'Ortóteses',
        body: 'Ortótese simples, práticas e de alta qualidade.',
        image: BraceID,
        icon: BraceIDLogo,
        avatar: AvatarBid
    },
    {
        id: 'brand_3',
        companyId: Company.id,
        name: 'LusaMed',
        type: 'Suplementos',
        body: 'Os suplementos essenciais para atingir os objetivos.',
        image: LusaMed,
        icon: LusaMedLogo,
        avatar: AvatarLmed
    },
];

export const ContactForms = {
    id: 'contactform1',
    variant: 'filled',
    fields: [
        {
            id: 'cf1_name',
            name: 'user_name',
            label: 'Nome',
            type: 'TextField',
            action: 'handleChange',
            multiline: false,
            rows: '',
            required: true,
        },
        {
            id: 'cf1_email',
            name: 'user_email',
            label: 'Email',
            type: 'TextField',
            action: '',
            multiline: false,
            rows: '',
            required: true,
        },
        {
            id: 'cf1_message',
            name: 'user_message',
            label: 'Messagem',
            type: 'TextField',
            action: '',
            multiline: true,
            rows: '7',
            required: true,

        },
        {
            id: 'cf1_submit',
            name: 'submit',
            label: 'Enviar',
            type: 'Button',
            action: 'submit',
        },
    
    ],
    action: 'Enviar',
    banner: {
        name: 'Contact banner',
        image: '',
    },
};

export const Products = [
    {
        id: 'p0ech',
        brandId: Brands.id,
        type: 'Cortinas Hospitalares',
        brand: 'Endurocide',
        display: '1',
        body: 'Alternativa às cortinas tradicionais para controlo da infeção hospitalar até 2 anos.',
        image: Endurocide,
        icon: EndurocideLogo,
        avatar: AvatarEnd,
        products: [
            {
                id: 'p0ech_p1',
                type: 'Cortina Hospitalar',
                name: 'Antimicrobiana e Esporicida ',
                brand: 'Endurocide',
                image: '',
                body: 'As cortinas Antimicrobianas e Esporicidas reduzem significamente o risco de Infecções Hospitalares que é transferido através das cortinas pelos prestadores de cuidados, pacientes e visitantes.',
                download: 'src/assets/endcatalogo1.pdf',
              
            },
            {
                id: 'p0ech_p2',
                type: 'Cortina Hospitalar',
                name: 'Antimicrobiana e Esporicida Padrões ',
                brand: 'Endurocide',
                image: '',
                body: 'Para além das cores disponibilizadas, a Endurocide criou uma gama de cortinas com as mesmas caracteristicas mas com padrões, para permitir dar uma outra vida aos espaços.',
                download: 'src/assets/endcatalogo1.pdf',

            },
            {
                id: 'p0ech_p3',
                type: 'Cortina Hospitalar',
                name: 'Descartáveis Sem Tratamento ',
                brand: 'Endurocide',
                image: '',
                body: 'Cortinas concebidas para locais com maior propensão à necessidade de trocas frequentes.',
                download: 'src/assets/endcatalogo1.pdf',
            },
            
        ],
        advantages: [
             'Longa duração (24 meses)',
             'Quebra a cadeia de infecção',
             'Retardante de fogo',
             '100% reciclável',
             'Equipada com laços laterais',
             'Leve',
             'Ganchos ajustáveis',
             'Adesivos de controlo interno',
        ],
        colors: [
            {
                name: 'Medical Blue',
                image: color1,
                hex: '#0116C0'
            },
            {
                name: 'Pastel Blue',
                image: color2,
                hex: '#74B9DD'
            },
            {
                name: 'Pastel Green',
                image: color3,
                hex: '#43BE9A'
            },
            {
                name: 'Pastel Yellow',
                image: color4,
                hex: '#F1DEA4'
            },
            {
                name: 'Latte',
                image: color5,
                hex: '#866221'
            },
            {
                name: 'Teal',
                image: color6,
                hex: '#2287A6'
            },
            {
                name: 'Lilac',
                image: color7,
                hex: '#E06ABB'
            },
            {
                name: 'Grey',
                image: color8,
                hex: '#556568'
            },
            {
                name: 'SDPY',
                image: color9,
                hex: '#556568'
            },
            {
                name: 'SDPB',
                image: color10,
                hex: '#556568'
            },
            {
                name: 'GBKWH',
                image: color11,
                hex: '#556568'
            },
            {
                name: 'GMBPM',
                image: color12,
                hex: '#556568'
            },
      

        ]
        
    },
    {
        id: 'p1bo',
        type: 'Ortóteses',
        brand: 'BraceID',
        display: '1',
        body: 'Ortóteses simples, práticas e de alta qualidade.',    
        image: BraceID,
        icon: BraceIDLogo,
        avatar: AvatarBid,
        products: [

            {
                id: 'p1bo_p1',
                type: 'Ortóteses',
                name: 'Coluna ',
                brand: 'BraceID',
                body: 'Concebidas com talas cobertas em tecidopara que se possa ser usada corretamente, com elásticos de alta qualidade para uma melhor respiração da pele.',
                products: [
                    {
                        id: 'braceid_col_1',
                        name: 'Colar cervical rígido',
                        image: colarcervicalrig,
                    },
                    {
                        id: 'braceid_col_2',
                        name: 'Colar Espuma',
                        image: colarespuma,
                    },
                    {
                        id: 'braceid_col_3',
                        name: 'Spineboard',
                        image: spineboard,
                    },
                    {
                        id: 'braceid_col_4',
                        name: 'Suporte Lombar',
                        image: suportelombar,
                    },
                    {
                        id: 'braceid_col_5',
                        name: 'Ortótese LomboSacral',
                        image: '',
                    },
                    {
                        id: 'braceid_col_6',
                        name: 'Cinta Abdominal',
                        image: cintaabdominal,
                    },
                    {
                        id: 'braceid_col_7',
                        name: 'Suporte de Clavícula',
                        image: '',
                    },
                ],
            },
            {
                id: 'p1bo_p2',
                type: 'Ortóteses',
                name: 'Membros Superiores ',
                brand: 'BraceID',
                body: 'Desenvolvidas para um suporte ideal e fácil adaptação. Utilizadas almofadas de silicone para uma pressão uniforme, mantendo a posição mesmo após flexão máxima.',
                products:  [
                    {
                        id: 'braceid_ms_1',
                        name: 'Sling Universal',
                        image: slinguniversal,
                    },
                    {
                        id: 'braceid_ms_2',
                        name: 'Imobilizador Ombro',
                        image: imobilizador,
                    },
                    {
                        id: 'braceid_ms_3',
                        name: 'Almofada Abdução 15º',
                        image: almofadaabducao,
                    },
                    {
                        id: 'braceid_ms_4',
                        name: 'OrtoSling',
                        image: ortosling,
                    },
                    {
                        id: 'braceid_ms_5',
                        name: 'ROM Cotovelo',
                        image: rom,
                    },
                    {
                        id: 'braceid_ms_6',
                        name: 'Braçadeira Epicondilite',
                        image: bracadeira,
                    },
                    {
                        id: 'braceid_ms_7',
                        name: 'Ortótese Punho Longa/Curta',
                        image: punholonga,
                    },
                    {
                        id: 'braceid_ms_8',
                        name: 'Ortótese Polegar e Punho',
                        image: polegarpunho,
                    },
                    {
                        id: 'braceid_ms_9',
                        name: 'Ortótese Polegar',
                        image: polegar,
                    },
                    {
                        id: 'braceid_ms_10',
                        name: 'Ortótese Dedo',
                        image: dedo,
                    },
                ],
            },
            {
                id: 'p1bo_p3',
                type: 'Ortóteses',
                name: 'Membros Inferiores ',
                brand: 'BraceID',
                body: 'Estruturadas para aumentar o conforto do paciente com peças adicionais de espuma alcochoada e manter a pressão adequada. Desenhadas para serem leves e rígidas.',
                products: [
                    {
                        id: 'braceid_mi_1',
                        name: 'Ortóteses 4 Punhos',
                        image: quatropontos,
                    },
                    {
                        id: 'braceid_mi_2',
                        name: 'ROM Joelho',
                        image: romjoelho,
                    },
                    {
                        id: 'braceid_mi_3',
                        name: 'ROM Joelho COOL',
                        image: romjoelhocool,
                    },
                    {
                        id: 'braceid_mi_4',
                        name: 'Imobilizador (0º / 20ª)',
                        image: imobilizadorjoelho,
                    },            
                    {
                        id: 'braceid_mi_5',
                        name: 'Protetor Pós-Trauma',
                        image: protetortrauma,
                    },
                    {
                        id: 'braceid_mi_6',
                        name: 'Joalheira Estabilização',
                        image: joalheiraesta,
                    },            
                    {
                        id: 'braceid_mi_7',
                        name: 'Joalheira Articulada',
                        image: joalheiraart,
                    },
                    {
                        id: 'braceid_mi_8',
                        name: 'Estabilizador TT',
                        image: estabilizadortt,
                    },            
                    {
                        id: 'braceid_mi_9',
                        name: 'Pé Elástico em "8"',
                        image: peelastico8,
                    },
                    {
                        id: 'braceid_mi_10',
                        name: 'Estabilizador TT-3Step',
                        image: estabilizadortt3,
                    },            
                    {
                        id: 'braceid_mi_11',
                        name: 'Walker Alta/Curta',
                        image: walkeralta,
                    },
                    {
                        id: 'braceid_mi_12',
                        name: 'Shield Alta/Curta',
                        image: shieldalta,
                    },            
                    {
                        id: 'braceid_mi_13',
                        name: 'Shield Alta/Curta - Air',
                        image: altaair,
                    },
                    {
                        id: 'braceid_mi_14',
                        name: 'ROM Walker - Air',
                        image: romwalker,
                    },            
                    {
                        id: 'braceid_mi_15',
                        name: 'Bota de Criança',
                        image: crianca,
                    },
                ],
            },

        ],

    },
    {
        id: 'p2ls',
        type: 'Suplementos Alimentares',
        brand: 'LusaMed',
        display: '1',
        body: 'Os suplementos essenciais para o dia a dia desenvolvidos por farmacêuticos.',
        image: LusaMed,
        icon: LusaMedIcon,
        avatar: AvatarLmed,
        products: [
            {
                id: 'p2ls_p1',
                type: 'Suplementos Alimentares ',
                name: 'GutDuoMax e GutFlora',
                brand: 'LusaMed',
                body: 'Destinam-se a complementar ou suplementar uma dieta normal e podem ser usados para corrigir carências nutricionais ou manter uma ingestão adequada de certos nutrientes.',
                image: '',
                products: [
                    {
                        id: 'p2ls_p1_p2',
                        name: 'GutDuoMax ',
                        image: gutduomax,
                        body: '8 saquetas',
                        pvr: '18,95',
                    },
                    {
                        id: 'p2ls_p1_p1',
                        name: 'GutFlora ',
                        image: gutflora,
                        body: '30 cápsulas',
                        pvr: '22,95',
                    },
                ],
            },            
            {
                id: 'p2ls_p2',
                type: 'Suplementos Alimentares ',
                name: 'GutDuoMax ',
                brand: 'LusaMed',
                body: 'Composto por 2 estirpes probióticas \n + Sais de hidratação(Sódio, Cloreto, Fósforo e Potássio) \n + Zinco + VitaminaC \n 8 saquetas - Toma única diária para crianças e adultos. \n',
                image: '',
            },
            {
                id: 'p2ls_p3',
                type: 'Suplementos Alimentares ',
                name: 'GutFlora ',
                brand: 'LusaMed',
                body: 'Composto por 10 estirpes bacterianas \n 30 cápsulas - Toma única diária \n Crianças a partir de um ano.',
                image: '',
            },

        ],
    },
]

