import React from 'react'
import Box from '@mui/material/Box'

import SectionContainer from '../../../containers/SectionContainer/SectionContainer'
import SectionContent from '../../../containers/SectionContainer/SectionContent'
import SectionTitle from '../../../containers/SectionContainer/SectionTitle'
import { Products } from '../../../constants/dataBase'
import ProductTitle from './LusaMed/ProductTitle'
import ProductTypes from './LusaMed/ProductTypes'
import DisplayProducts from './LusaMed/DisplayProducts'

function LusaMed() {
   const listOfBrands = Products
   const brand = listOfBrands.find(({brand}) => brand === 'LusaMed')
   const products = brand.products 
   
  return (
    <>
        <Box sx={[{flexGrow:'1'}]}>
            <SectionContainer>
                <SectionContent width='12'>
                  <SectionTitle title={brand.brand}/>
                </SectionContent>

                <SectionContent width='12'>
                  <ProductTypes list={products}/>
                </SectionContent>
            </SectionContainer>

            <Box sx={{marginBottom:'-80px'}}/>
            
            <SectionContainer>
                <SectionContent width='12'>
                    <ProductTitle/>
                </SectionContent>
       
                <SectionContent width='12'>
                  <DisplayProducts list={products[0].products}/>
                </SectionContent>
            </SectionContainer>
        </Box>
    </>
  )
}

export default LusaMed