
import React, {useId} from 'react'
import ArticlesContainer from '../ArticlesContainer/ArticlesContainer'
import SectionContainer from '../SectionContainer/SectionContainer'
import {Brands} from '../../constants/dataBase'
import { FeaturedContext } from '../../constants/contexts'
import { Box } from '@mui/material'

const styles = {
    lightgreyContainer: {
        backgroundColor: '#FAFAFA'
    }
  }

function FeaturesContainer() {
    const ID = useId()
    const arr = Brands[0]
    const product = Brands


    return (
        <>
            <Box id='FeaturesContainer' sx={[styles.lightgreyContainer]}>
                <SectionContainer key={ID+product.brand}>
                    <FeaturedContext.Provider value={product.products}>
                        <ArticlesContainer key={ID+product.brand} title={arr.companyId} index={0}/>
                    </FeaturedContext.Provider>
                </SectionContainer>
            </Box>
        </>
    )
}

export default FeaturesContainer