import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import lusamedLogo from '../../assets/lusamedhalflogo.png'
import { navLinks } from '../../constants/dataBase'
import { CardMedia } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const styles = {
    logo: {
        height:'30px',
        objectFit: 'contain',
        width:'min-content',
        backdropFilter: ''
    }
}

const links = navLinks

function NavBarContainer(props) {
    let navigate = useNavigate()

    const routeChange = (path) => {
        navigate('/')
        props.setPath(path)
    }
  
  return (
    <AppBar position='sticky' color='transparent' 
        sx={{backdropFilter: 'blur(40px)', backgroundColor:'#E6E9EC99', boxShadow: 'inset 0 -1px 1px #E6E9EC'}}
    >
        <Container maxWidth='lg'>
 
        <Toolbar disableGutters sx={{zIndex: '1'}}>
            <a href='/' sx={{all:'unset', cursor:'pointer'}}>
                <CardMedia component='img' src={lusamedLogo} alt='Lusamed' sx={[styles.logo]}/>
            </a>

            <Box sx={{ flexGrow: '1', display: { xs: 'none', md: 'flex' },marginRight: '-14px', justifyContent: 'flex-end' }}>
                {links.map((link) => ( link.display ?
                    <Button key={link.id} href='' onClick={()=>routeChange(link.path)} sx={{color:'#083D83', height: '56px' }}>
                        {link.name}
                    </Button >
                        : null
                 ))}
            </Box>
            
        </Toolbar>
        </Container>
    </AppBar>
  )
}

export default NavBarContainer