import { Grid } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'

const styles = {
    gridItem: {
        display: 'flex',
        flexGrow: '1',
        paddingTop: '20px',
        paddingLeft: '20px',
        flexWrap: 'wrap',
    },
}

function SectionContent(props) {
    const ref = useRef()
    const {children, width} = props

    useEffect(() => {
        if (width === '12') {
            ref.current.style.flexDirection='row'
            ref.current.style.marginLeft='0'
            ref.current.style.marginTop='-20px'
        } else ref.current.style.flexDirection='column'
    }, [width])

    return (
        <>
            <Grid ref={ref} item xs={12} md={width} sx={styles.gridItem}>
                {children}
            </Grid>
        </>
    )
}

export default SectionContent