import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import SectionContent from '../../../../containers/SectionContainer/SectionContainer'

const styles = {
    colorBox: {
        display:'block',
        overflow:'hidden',
        flexGrow: '1',
        padding: {xs:'10px', md:'5px'},
        borderRadius: '10px',
        border: '1px solid #E6E9EC',
        height: '100%',
        backgroundColor:'#fff',
    },
    color: {
        flexGrow:'1',
        borderRadius: '10px',
        backgroundPosition:'center',
        backgroundSize:'100%',
        backgroundRepeat: 'no-repeat',
        aspectRatio: '1 / 1',
    }
}

function ProductColors(props) {
    const {list} = props

    return (
      <>
        <Box sx={{flexGrow: '1', marginTop:'-40px'}}>
        
        <SectionContent width='12'>
            <Grid container sx={{marginLeft:'-20px', width:'calc(100% + 20px)'}}>
            {list.map((value) => {
                return (
                    <Grid item xs={4} md={3} sx={{paddingTop:'20px', paddingLeft: '20px'}}>
                        <Box sx={[styles.colorBox]}>
                            <Box sx={[styles.color, { backgroundImage: 'url('+value.image+')' }]}/>
                        </Box>
                    </Grid>
                )
            })}
            <Box sx={{display:'flex', flexDirection:'column'}}>
                <Box sx={{display:'flex', flexDirection: 'row', flexGrow: '1', paddingLeft:'20px', flexWrap: 'wrap'}}>
                    <span style={{fontSize:'0.6rem', paddingTop:'10px'}}>* {list.map(({name}, index) => <Typography variant='caption'>{index+1} {name}, </Typography>)}</span>
                </Box>
                <Box sx={{display:'flex', flexDirection: 'row', flexGrow: '1', paddingLeft:'20px', flexWrap: 'wrap'}}>
                    <span style={{fontSize:'0.6rem', paddingTop:'3px'}}>* <Typography variant='caption'> Outras cores disponiveis sob consulta.</Typography></span>
                </Box>
            </Box>

            </Grid>
        </SectionContent>
      </Box>
    </>
    )
}

export default ProductColors

