import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import lusamedlogo from '../../assets/lusamedlogo.png'
import { useNavigate } from 'react-router-dom';
import bgImage from '../../assets/bgheadline.jpg'

const color1= '#083D83'
const color2= '#0b57ba'

const color3= '#03989D'
const color4= '#016366'

const styles = {
    h1: {
        fontSize: 'clamp(2.625rem, 1.285rem + 3.571vw, 4rem)',
        marginTop: '20px',
        marginBottom: '20px',
        color: '#0A1929'
    },
    body1: {
        color: '#365060',
        width:'550px',
        paddingTop: '40px'
    },
    button: {
        padding: '0.875rem 1rem',
        width: '150px',
        minWidth: 'clamp(0px, (500px - 100%) * 999 ,100%)',
        marginRight: '20px',
    },
    space: {
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    color: {
        backgroundColor: 'primary',
        backgroundImage:'linear-gradient(166deg, '+color3+' 18%, '+color4+' 78%)',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },

    headlineContainer: {
        display:'flex',
        height: 'calc(100vh - 60px)',
        backgroundColor: '#FAFAFA',
        overflow: 'hidden',
    },
    gridContainer: {
        display: 'flex',
        alignContent: {xs:'center', md:'flex-start'},
        flexGrow: '1',
        height: '100%',
        marginTop: '-20px',
        marginLeft: '-20px',
        width: 'calc(100% + 20px)',
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: {xs:'center', md:'flex-start'},
        flexGrow: '1',
        paddingTop: '20px',
        paddingLeft: '20px',
    },
    contentContainer: {
        display: 'block',
        flexDirection: 'column',
        marginTop:'80px',
        maxWidth: {xs:'550px', xl:'550px'},
        textAlign:{xs: 'center', md:'left'},
        zIndex: '11'

    },
    imageContainer: {
        display:{xs:'none', md:'flex'},
        position: 'absolute',
        width:'100vw',
        top:'0',
        height: '600px',
        overflow: 'hidden',
        backgroundImage: 'url('+bgImage+')',
        backgroundBlendMode: 'multiply',
        backgroundPosition: 'Top',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        opacity: '0.4',

    },

}

const subtitle = 'Somos uma empresa farmacêutica que atua na área da saúde e bem estar, que existe com o propósito de melhorar a vida dos pacientes. '

function HeadlineContainer() {
    let navigate = useNavigate()

    const routeChange = (path) => {
        navigate('/')
        document.getElementById(path).scrollIntoView({behavior: 'smooth'})
    }

  return (
    <>
        <Box sx={[styles.headlineContainer]}>

        <Box sx={[styles.imageContainer]}/>
            <Container maxWidth='lg'>
                <Box sx={{display:'flex', height:'560px'}}>
                    <Grid container sx={[styles.gridContainer]}>
                        <Grid item sm={12} md={6} sx={[styles.gridItem]}>
                            <Box sx={[styles.contentContainer]}>
                                <Typography variant='h1' sx={[styles.h1]}><span style={styles.color}>Consiga mais</span> e melhor com os nossos produtos</Typography>
                                <Box>
                                    <Button href='' onClick={()=> routeChange('productsSection')} variant='contained' sx={[styles.button]}>Produtos</Button>
                                    <Box sx={[styles.space, {display: {xs: 'block', sm: 'none'}}]}/>
                                    <Button href='' onClick={()=> routeChange('contactSection')} variant='outlined' sx={[styles.button]}>Saber mais</Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{display:'flex', textAlign:'center', position:'relative', justifyContent:'center', flexGrow:'1', }}>
                    <Typography variant='body1' sx={[styles.body1]}>{subtitle}</Typography>
                </Box>
                <Box sx={{display:'flex', textAlign:'center', justifyContent:'center', flexGrow:'1', paddingTop:'20px'}}>
                    <CardMedia component='img' src={lusamedlogo} alt='Lusamed' sx={{objectFit:'contain', width:'100px', aspectRatio: '100 / 52'}}/>
                </Box>
            </Container>

        </Box>
    </>
  )
}

export default HeadlineContainer
