import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import React, { useId } from 'react'

import SectionContent from '../../../containers/SectionContainer/SectionContent'
import SectionTitle from '../../../containers/SectionContainer/SectionTitle'
import ValueCard from '../components/ValueCard'


const styles = {
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        paddingLeft: '20px',
        paddingTop: '20px',
    },


}

function ValuesContainer({arr}) {
    const ID = useId()
  
  return (
    <>
        <SectionContent width='6'>
            <SectionTitle title='Values'/>
        </SectionContent>
        <SectionContent width='12'>
            <Box sx={{marginLeft:'-20px', marginTop:'-20px', display: 'flex', flexWrap: 'wrap'}}>
                {arr.map((value, index) => {
                    return (
                        <Grid key={ID+arr.name} item xs={12} sm={6} md={3} sx={[styles.gridItem]}>
                            <ValueCard key={ID+arr.name+index} name={value.name} body={value.body} icon={value.icon}/>
                        </Grid>
                    )
                })}
            </Box>

        </SectionContent>
            
    </>
  )
}

export default ValuesContainer