import { Container, Grid } from '@mui/material'
import React from 'react'
import InfoCard from '../components/InfoCard'
import SponsorCard from '../components/SponsorCard'

const styles = {
    gridContainer: {
        boxSizing: 'border-box',
        display: 'flex',
        marginTop: '-40px',
        marginLeft: '-40px',
        width: 'calc(100% + 40px)',
        minHeight: '52px'
    },    
    gridItem: {
        display: 'flex',
        flexGrow: '1',
        paddingTop: '40px',
        paddingLeft: '40px',
    },
}

function SponsorContainer(props) {
    const sponsorList = props.array
   
  return (
    <>
   
            <Container maxWidth='lg'>
                <Grid container sx={[styles.gridContainer]}>
                    {sponsorList.map((sponsor) => {
                        return(
                            <Grid item xs={6} sm={4} md={2.4} sx={[styles.gridItem]}>
                                <SponsorCard key={sponsor.id} image={sponsor.image} alt={sponsor.name} url={sponsor.url}/>
                            </Grid> 
                        )               
                    })}
                    <Grid item sm={12} sx={[styles.gridItem]}>
                        <InfoCard/>
                    </Grid>
                </Grid>
            </Container>
 
    </>
  )
}

export default SponsorContainer