import { Box, Typography } from '@mui/material'
import React from 'react'

const styles = {
    infoContainer: {
        display: 'flex',
        flexGrow: '1',
        minHeight: '42px',
        textAlign: 'center',
        justifyContent: 'center',
        marginTop: '40px',
    },
}

function InfoCard() {
  return (
    <>
        <Box sx={[styles.infoContainer]}>
            <Typography variant='body2' sx={{maxWidth: '400px'}}>
                Valorizamos a pesquisa e o desenvolvimento de soluções revolucionárias que façam o mundo avançar. 
            </Typography>
        </Box>
    </>
  )
}

export default InfoCard