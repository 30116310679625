import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

function ProductTitle() {
  return (
    <>
        <Box sx={{display:'block', flexBox: '1', width:'100%', paddingBottom:'20px'}}>
            <Typography variant='body2' 
            sx={{fontWeight: '700', marginBottom: '10px',}}
            >Especificações</Typography>
            <Divider/>
        </Box>
    </>

  )
}

export default ProductTitle