import Box from '@mui/material/Box'
import React from 'react'
import SectionContainer from '../../containers/SectionContainer/SectionContainer'
import SectionContent from '../../containers/SectionContainer/SectionContent'
import SectionTitle from '../../containers/SectionContainer/SectionTitle'
import ContactForm from './ContactForm'
import ContactPerson from '../../assets/contactperson.png'

const styles = {
  lightgreyContainer: {
    backgroundColor: '#FAFAFA'
  },
  imageContainer: {
    display:'block',
    position: 'relative',
    height:'100%',
    zIndex:'10',
    paddingBottom:'40px'
  },
  imageObject: {
    display:'Block',
    height:'160%',
    backgroundImage: 'url('+ContactPerson+')',
    backgroundBlendMode: 'multiply',

    backgroundPosition: {md:'80px 100px', lg:'80px 0px'},
    backgroundSize: '85%',
    backgroundRepeat: 'no-repeat',
    opacity:'0.89',
    filter: 'brightness(96%) contrast(105%) drop-shadow(0px 6px 20px lightgrey)'
  },
}

function ContactContainer() {
    const title = 'Contacto'
  return (
    <>
      <Box sx={[styles.lightgreyContainer]}>
        <SectionContainer>
            <SectionContent width='6'>
                <SectionTitle title={title}/>
                <ContactForm/>
            </SectionContent>
            <SectionContent width='6'>
              <Box sx={[styles.imageContainer]}>
                <Box sx={[styles.imageObject]}/>
              </Box>
            </SectionContent>
        </SectionContainer>
      </Box>
    </>
  )
}

export default ContactContainer

/**
 *                 <CardMedia component='img' src={ContactPerson} alt='Contact' sx={[styles.imageObject]}/>

 */