import { Avatar, Box, CardContent, CardHeader, Typography } from '@mui/material'
import React from 'react'

const styles = {
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    padding: '20px',
    borderRadius: '10px',
    color:'',
  },
  headerContainer: {
    display: 'flex',
    padding:'0',
    marginBottom: '10px',
    alignItems: 'center',
  },
  clearPadding: {
    padding:'0 !important',
    margin:'0',
  },
}

function TypeCard(props) {
  const { name, body } = props

  return (
      <Box sx={[styles.cardContainer]}>
        <Box sx={[styles.headerContainer]}>
          <CardHeader sx={[styles.clearPadding]}
            avatar={<Avatar variant='circle' alt={name} sx={{backgroundColor:'#03989D', color: '#03989D', height:'36px', width: 'px'}}/>} />
          <Typography variant='body2' sx={{fontWeight: '800'}}>{name}</Typography>
        </Box>
        <CardContent sx={[styles.clearPadding]}>
          <Typography variant='body2' sx={[styles.clearPadding]}>{body}</Typography>
        </CardContent>
      </Box>
  )               
}

export default TypeCard