import React from 'react'
import BraceID from './BraceID'
import Endurocide from './Endurocide'
import LusaMed from './LusaMed'

function DisplayProducts(props) {
    const {product} = props
  return (
  
        product === 'Endurocide' ? (
            <Endurocide/>)
         :  product === 'BraceID' ? 
                (<BraceID/> )
                    : (<LusaMed/>)

  )
}

export default DisplayProducts