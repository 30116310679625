import { Container, Box, Typography, List, ListItem, ListItemButton, Grid, CardMedia } from '@mui/material'
import React from 'react'
import { navLinks } from '../../constants/dataBase'
import { Sponsors, Products } from '../../constants/dataBase'
import cert1 from '../../assets/c-13485_Zertifikatssiegel_black_A3-180px-1.jpeg'
import cert2 from '../../assets/c-BSI-ISO-14001.png'
import cert3 from '../../assets/c-BSI-ISO-9001.png'
import cert4 from '../../assets/c-IQNet-Schwarz-180px-1.jpeg'
import lusamedlogo from '../../assets/lusamedlogo.png'
import { useNavigate } from 'react-router-dom'

const styles = {
    toolbarContainer: {
        display: 'flex',
        marginLeft:'-20px',
        paddingTop: {xs: '0px', md: '40px'},
        minWidth: 'calc(100% + 20px)',
        justifyContent: 'flex-start',
    },
    background: {
        backdropFilter: 'blur(40px)', 
        backgroundColor:'#E6E9EC99', 
        boxShadow: 'inset 0 -1px 1px #E6E9EC'
    },
    logoContainer: {
        display:'flex',
        paddingLeft:'20px',
        paddingTop: '20px',
    },
    linksContainer: {
        paddingTop: '20px',
        paddingLeft:'20px',
        display: { xs: 'none', md: 'block' },
    },
    linksCertContainer: {
        marginBottom: '10px',
        display: { xs: 'none', md: 'flex' },
        flexWrap: 'wrap'
    },
    certContainer: {
        display: 'flex',
        flexGrow: '1',
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    cardImage: {
        display: 'block',
        marginLeft: '20px',
        height:'70px',
        width:'70px',
        objectFit:'contain',
        backgroundBlendMode: 'multiply',
        backgroundColor: '#03989E',

        backdropFilter: 'blur(40px)',
    },
    logo: {
        height:'110px',
        width: '220px'
    }
}

const links = navLinks
const sponsorsList = Sponsors
const productsList = Products

function FooterContainer(props) {
    let navigate = useNavigate()

    const routeChange = (path) => {
        navigate('/')
        props.setPath(path)
    }
  return (
    <>
        <Box sx={[styles.background, {display:'block', position:'relative', flexGrow: 1, zIndex:'1200', paddingBottom:{xs: '20px', md:'40px'}}]}>
            <Container maxWidth='lg'>
                <Grid container sx={[styles.toolbarContainer]}>
                    <Grid item md={3} sx={[styles.logoContainer]}>
                        <CardMedia component='img' src={lusamedlogo} sx={[styles.logo]}/>
                    </Grid>
            
                    <Grid item md={2} sx={[styles.linksContainer]}>
                        <Typography variant='body1' fontWeight='700'>Navegação</Typography>
                        <List >
                            {links.map((link, index) => ( link.display ? 
                                <ListItem key={link.name+index+3} disablePadding>
                                    <ListItemButton sx={{all:'unset', cursor: 'pointer'}} disableGutters key={index+5+link.name} onClick={()=>routeChange(link.path)}>
                                        {link.name}
                                    </ListItemButton>
                                </ListItem> : null
                            ))}
                        </List>
                    </Grid>

                    <Grid item md={2} sx={[styles.linksContainer]}>
                        <Typography variant='body1' fontWeight='700'>Parceiros</Typography>
                        <List >
                            {sponsorsList.map((sponsor, index) => {
                                return (
                                    <ListItem key={sponsor.name+index+3} disablePadding>
                                        <ListItemButton sx={{all:'unset', cursor: 'pointer'}} disableGutters key={index+5+sponsor.name} href={sponsor.url}>
                                            {sponsor.name}
                                        </ListItemButton>
                                    </ListItem> 
                                )
                            })}
                        </List>
                    </Grid>

                    <Grid item md={2} sx={[styles.linksContainer]}>
                        <Typography variant='body1' fontWeight='700'>Produtos</Typography>
                        <List >
                            {productsList.map((product, index) => {
                                return (
                                    <ListItem key={product.name+index+3} disablePadding>
                                        <ListItemButton sx={{all:'unset', cursor: 'pointer'}} disableGutters key={index+5+product.brand} href={product.id}>
                                            {product.type}
                                        </ListItemButton>
                                    </ListItem> 
                                )
                            })}
                        </List>
                    </Grid>
               
                    <Grid item md={3} sx={[styles.linksCertContainer]}>
                        <Box sx={[styles.certContainer]}>
                            <CardMedia component='img' height='50px' image={cert2} alt='certiticação' sx={[styles.cardImage]}/>
                            <CardMedia component='img' height='50px' image={cert3} alt='certiticação' sx={[styles.cardImage]}/>
                        </Box>
                        <Box sx={[styles.certContainer]}>
                            <CardMedia component='img' height='50px' image={cert1} alt='certiticação' sx={[styles.cardImage]}/>
                            <CardMedia component='img' height='50px' image={cert4} alt='certiticação' sx={[styles.cardImage]}/>
                        </Box>
                    </Grid>
                </Grid>   
            </Container>
        </Box>
    </>
  )
}

export default FooterContainer