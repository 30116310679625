import React from 'react'
import SponsorContainer from '../container/SponsorContainer'
import {Sponsors} from '../../../constants/dataBase'
import { Box } from '@mui/material'

const styles = {
    sponsorContainer: {
        display: 'block',
        paddingTop: '100px',
        paddingBottom: '100px',
    }
}

const sponsors = Sponsors

function ViewSponsors() {

    return (
        <>
            <Box sx={[styles.sponsorContainer]}>
                <SponsorContainer array={sponsors}/> 
            </Box>

        </>
    )
}

export default ViewSponsors